import React from 'react'
import { graphql } from 'gatsby'
//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/realestate.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'

import kpi from '../images/Real-Estate-kpi.svg'
import idengager from '../images/Real-Estate-idengager.svg'
import idataworkers from '../images/Real-Estate-idataworkers.svg'
import iopsapps from '../images/Real-Estate-iopsapps.svg'
import unitsCatalog from '../images/units.svg'
import eCommerce from '../images/e-commerce-real-estate.svg'
import installmentsManagment from '../images/tenancy-management.svg'
import requestQuote from '../images/quots.svg'
import { Navbar } from '../components/navbar'
import Features from '../components/features/features'
import { MinusOutlined } from '@ant-design/icons'
import ExploreFeatures from '../components/exploreFeatures/exploreFeatures'
import IMAGE1 from '../images/image-6.png'
import IMAGE2 from '../images/image-7.png'
import { StaticImage } from 'gatsby-plugin-image'

//import supportUser from "../images/support-user.svg";
//import branches from "../images/branches.svg";
//import manageYourUnits from "../images/manage.svg";
//import realTime from "../images/real-time.svg";
//import tenancy from "../images/tenancy.svg";
//import confirmation from "../images/services-confirmation.svg";
//import service from "../images/servicerequest.svg";
//import work from "../images/confirm.svg";
//import contracts from "../images/services-contracts.svg";
//import dashboard from "../images/services-dashboard.svg";
const Realestate = ({ data }) => (
	<Layout>
		<SEO
			title="features in Real Estate Software Solutions"
			keywords={['real estate software solutions']}
			description="we are dedicated to providing innovative real estate software solutions that streamline the process of buying and selling properties."
		/>
		<div className="realestate-page">
			<Navbar />
			<div className="page-content realestate-content">
				<div className="realestate-section">
					<div className="header-content">
						<div className="headerLeft">
							<h1>Real Estate Software Solutions for Streamlining Your Business</h1>
							<h2>
								Real Estate Software Solutions for Improved Property Management
							</h2>
							<p>
								is essential for any property owner or manager looking to
								improve your business. Since iCloud-Ready presents
								comprehensive, timely, and accurate data that can be used to
								manage properties more efficiently and streamline interactions
								with tenants, managers, and owners.
							</p>
							<CustomButton className="link-wrapper">
								<Link
									className="demo-request"
									data-track-location="realestate"
									to="/contactus"
								>
									Request a Demo
								</Link>
							</CustomButton>
						</div>
						<div className="circle"></div>
					</div>
				</div>
				<Features />

				<section className="products-content" id="products-content">
					<span>Offering</span>
					<div className="product-parent">
						<div className="product-details">
							<h3 className="title">Power through your day</h3>
							<p className="subtitle">
								Structure your daily operations and communication so that you
								may concentrate on growing your business rather than
								time-consuming tasks.
							</p>
							<ul>
								<li>
									<MinusOutlined />
									<p>
										Maintain communication with residents, owners,
										board members, and vendors.
									</p>
								</li>
								<li>
									<MinusOutlined />
									<p>
										Complete maintenance requests and work orders as
										soon as possible.
									</p>
								</li>
								<li>
									<MinusOutlined />
									<p>On the go, manage and track association breaches.</p>
								</li>
							</ul>
							<CustomButton className="link-wrapper">
								<Link className="product-btn" to="/pricing">
									Request a Demo
								</Link>
							</CustomButton>
						</div>
						<div className="product-img">
							<img src={kpi} className="item-img" alt="kpi" />
						</div>
					</div>
					<div className="product-parent reverse-parent">
						<div className="product-details">
							<h3 className="title">Reduce the length of your leasing cycle.</h3>
							<p className="subtitle">
								Bring your whole lead-to-lease cycle online so that you can
								concentrate on people rather than paperwork.
							</p>
							<ul>
								<li>
									<MinusOutlined />
									<p>
										Effortlessly promote available units and schedule
										showings
									</p>
								</li>
								<li>
									<MinusOutlined />
									<p>
										Create unique rental applications and conduct
										thorough tenant screening
									</p>
								</li>
								<li>
									<MinusOutlined />
									<p>
										Create templates for all of your lease contracts,
										and use any device to collect applicant
										signatures.
									</p>
								</li>
							</ul>
							<CustomButton className="link-wrapper">
								<Link
									className="product-btn"
									data-track-location="realestate"
									to="/pricing"
								>
									Request a Demo
								</Link>
							</CustomButton>
						</div>
						<div className="product-img">
							<img src={idengager} className="item-img" alt="idengager" />
						</div>
					</div>
					<div className="product-parent">
						<div className="product-details">
							<h3 className="title">Set your accounting on auto-pilot</h3>
							<p className="subtitle">
								Automate your bookkeeping, payments, and collections so that you
								may concentrate on the line items that are most important.
							</p>
							<ul>
								<li>
									<MinusOutlined />
									<p>Manage every step of the payment process online.</p>
								</li>
								<li>
									<MinusOutlined />
									<p>
										Maintain complete and accurate records for your
										property and business finances.
									</p>
								</li>
								<li>
									<MinusOutlined />
									<p>File and track your Form 1099s in minutes.</p>
								</li>
							</ul>
							<CustomButton className="link-wrapper">
								<Link
									className="product-btn"
									data-track-location="realestate"
									to="/pricing"
								>
									Request a Demo
								</Link>
							</CustomButton>
						</div>
						<div className="product-img">
							<img src={idataworkers} className="item-img" alt="idataworkers" />
						</div>
					</div>
					<div className="breakup-container">
						<div className="breakupSection">
							<div>
								<h2>
									Already convinced iCloud-Ready Platform is right for your
									business?
								</h2>
								<p>
									Take the platform for a spin with our free,
									no-obligation, 14-day trial. No credit card required!
								</p>
							</div>
							<div>
								<span></span>
								<Link to="/contactus">
									<button>Get In Touch</button>
								</Link>
							</div>
						</div>
					</div>
					<div className="product-parent">
						<div className="product-details">
							<h3 className="title">Give your business the attention it deserves</h3>
							<p className="subtitle">
								Instead of learning how to design a website, attract residents
								and get new customers.
							</p>
							<ul>
								<li>
									<MinusOutlined />
									<p>
										Create a free property management website to
										promote your business, showcase available
										properties, and highlight the services you
										provide.
									</p>
								</li>
								<li>
									<MinusOutlined />
									<p>
										Using All Property Management, you may reach out
										to property owners in your region and anywhere
										else you wish to expand.
									</p>
								</li>
							</ul>
							<CustomButton className="link-wrapper">
								<Link
									className="product-btn"
									data-track-location="realestate"
									to="/pricing"
								>
									Request a Demo
								</Link>
							</CustomButton>
						</div>
						<div className="product-img ">
							{/* <img src={IMAGE2} className="item-img borderd" alt="iopsapps" /> */}
							<StaticImage
								src="../images/image-7.png"
								className="item-img borderd"
								alt="iopsapps"
								imgStyle={{ objectFit: 'scale-down' }}
								placeholder="blurred"
							/>
						</div>
					</div>
					<div className="product-parent reverse-parent">
						<div className="product-details">
							{/* <h4>BUSINESS PERFORMANCE</h4> */}
							<h3 className="title">
								Make informed decisions
								<br /> (right away)
							</h3>
							<p className="subtitle">
								Make the best decisions in real time by having all of your
								company&#39;s activity monitored from one location.
							</p>
							<ul>
								<li>
									<MinusOutlined />
									<p>
										A complete suite of reporting will help you
										comprehend the performance of your entire
										portfolio. Analytics &amp; Insights can help you
										strategically manage your organization.
									</p>
								</li>
								<li>
									<MinusOutlined />
									<p>
										Using Open API to create custom integrations will
										increase the efficiency of data workflow.
									</p>
								</li>
							</ul>
							<CustomButton className="link-wrapper">
								<Link
									className="product-btn"
									data-track-location="realestate"
									to="/pricing"
								>
									Request a Demo
								</Link>
							</CustomButton>
						</div>
						<div className="product-img">
							{/* <img src={IMAGE1} className="item-img borderdReverse" alt="iopsapps" /> */}
							<StaticImage
								src="../images/image-6.png"
								className="item-img borderdReverse"
								alt="iopsapps"
								imgStyle={{ objectFit: 'scale-down' }}
								placeholder="blurred"
							/>
						</div>
					</div>
				</section>

				{/* <ExploreFeatures title={'Explore'} subtitle={'All Features'} /> */}
			</div>
			<div className="footer-section">
				<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
			</div>
		</div>
	</Layout>
)
export const pageQuery = graphql`
	query RealestateQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Realestate
