import React from 'react'
import './features.styles.scss'
import { Link } from 'gatsby'
import Star from '../../images/Star.svg'

const Features = () => {
	return (
		<div className="features">
			<h1>
				iCloud-Ready&#39;s core technology is designed to reduce overhead costs and accelerate your
				ability to scale up your businesses.
			</h1>
			<p>Our new software is built for companies wanting to grow and invest into the future.</p>
			<div className="featuresItemsContainer">
				<div className="item">
					<img
						src={Star}
						alt="ICON"
					/>
					<div>
						<h3>All combinations of portfolios</h3>
						<p>
							From individual units to multi-block complexes, both residential and commercial, we will assist you with all of your portfolio mixing needs.
						</p>
					</div>
				</div>
				<div className="item">
					<img
						src={Star}
						alt="ICON"
					/>
					<div>
						<h3>Outstanding support</h3>
						<p>Assisting teams in customer service and on boarding are available at all times.</p>
					</div>
				</div>
				<div className="item">
					<img
						src={Star}
						alt="ICON"
					/>
					<div>
						<h3>Continuous improvement</h3>
						<p>
							Staying ahead of the competition in the property management sector by delivering innovative features that give you an unbeatable advantage.
						</p>
					</div>
				</div>
				<div className="item">
					<img
						src={Star}
						alt="ICON"
					/>
					<div>
						<h3>No limit on the number of units</h3>
						<p>
							Pricing that is easily adjustable and transparent, allowing you to pay only for what is necessary.
						</p>
					</div>
				</div>
			</div>
			{/* <Link to="/feature-detalis">See All Features</Link> */}
		</div>
	)
}

export default Features
